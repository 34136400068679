<template>
  <div v-if="maxLength !== undefined" class="input-current-and-max-length">
    <span>{{ currentLength }}/{{ maxLength }}</span>
  </div>
</template>
<script>
import { countTextLenghtWithEmojis } from '@/utils/emojis-parser'

export default {
  props: {
    value: {
      type: String
    },
    maxLength: {
      type: Number,
      required: false
    },
    charactersCountWidth: {
      type: String,
      default: '60px'
    }
  },
  computed: {
    currentLength() {
      const isString = typeof this.value === 'string'
      return isString ? countTextLenghtWithEmojis(this.value) : 0
    }
  }
}
</script>
<style lang="scss" scoped>
.input-current-and-max-length {
  // if input has errors then it will look prettier when we use position absolute
  position: absolute;
  left: calc(100% - v-bind(charactersCountWidth));
  width: v-bind(charactersCountWidth);
  height: 20px;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: $neutral-3;
}
</style>
